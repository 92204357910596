.installPage {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.installButton {
    width: 75%;
    color: #000 !important;
    background: #73e261 !important;
    margin-bottom: 38px !important;
}

.installButton:hover {
    background: #73e261
}

.installButton:active {
    background: #73e261
}

.installPage img {
    width: 150px;
    margin: 16px;
}