html, body {
    padding: 0 !important;
    margin: 0 !important;
    background: #000;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    overflow: hidden;
    width: 100%;
    /* prevent overscroll bounce*/
    height: 100%;
    -webkit-overflow-scrolling: touch;
}

#root {
    height: 100%;
    max-height: 100%;
}

.browser {
    background: #fff !important;
    border: 1px solid #cdcd !important;
    color: #000 !important;
    width: 100% !important;
    padding: 1rem !important;
    margin-top: 8px !important;
}

.buttonContent {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.buttonContent>img {
    position: absolute;
    left: 8px;
    margin-right: 8px;
    width: 50px;
}

.bounce {
    -webkit-animation: mover 0.5s infinite  alternate;
    animation: mover 0.5s infinite  alternate;
}
@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(10px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(10px); }
}
